import React, { useState } from 'react';
import logo from './logo.svg';
import { initializeApp } from 'firebase/app';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

import './App.css';

const firebaseConfig = {
  apiKey: 'AIzaSyCZYQx5RoOf6jR2TemfSsN1Z0qDChG-Ztc',

  authDomain: 'brazenleftist.firebaseapp.com',

  projectId: 'brazenleftist',

  storageBucket: 'brazenleftist.appspot.com',

  messagingSenderId: '1025728477567',

  appId: '1:1025728477567:web:d15a60d3b6c9676b70024a',
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

function App() {
  const [firstName, setFirstName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [accepted, setAccepted] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [hasSubmitted, setHasSubmitted] = useState<boolean>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>();
  const subscribe = async () => {
    if (!firstName || !email || !accepted) {
      return;
    }
    try {
      setIsSubmitting(true);
      await setDoc(doc(db, 'subscribers', email), {
        firstName,
        email,
      });
      setHasSubmitted(true);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      setError('There was an error');
    }
  };
  return (
    <div className="">
      <div className="background">
        <svg
          width="400px"
          height="400px"
          version="1.1"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path d="m229.52 69.152c3.2305 2.8672 5.6094 7.5195 4.1797 12.844l-15.641 58.625-36.977-5.5234 16.027-59.852c1.4805-5.5 6.168-10.07 12.949-11.344 6.8359-1.3008 14.84 1.168 19.461 5.25z" />
            <path d="m279.75 107.38-14.582 44.402c-1.5977 4.8633-4.0898 5.457-6.168 2.0547-2.6641-4.4102-6.9961-8.125-13.629-9.125l-15.996-2.4102 12.652-47.168c5.3594-20.023 45.25-10.754 37.723 12.246z" />
            <path d="m307.62 102.06c5.2148-0.17578 10.461 2.168 14.312 5.6328 5.125 4.6133 7.2578 10.898 5.5469 16.289 0 0-14.637 45.512-21.926 68.234-1.7539 5.4219-6.1953 9.9336-12.949 11.355-7.1953 1.543-15.555 0.21094-20.242-4-5.125-4.625-7.0195-10.758-5.5469-16.242l22.613-69.234c2.9102-8.9219 10.172-11.789 18.191-12.035z" />
            <path d="m350.5 126.43c5.2109-0.058593 10.461 2.168 14.309 5.6328 5.0977 4.6133 6.8789 10.898 5.6055 15.578l-15.91 57.719c-1.5117 5.4766-6.1602 9.8867-12.918 11.32-6.7578 1.4453-14.371-0.58984-19.488-5.2227-5.125-4.625-7.0273-10.781-5.5469-16.254l19.199-59.836c2.332-7.2344 8.1914-8.8867 14.75-8.9375z" />
            <path d="m163.66 144.02 78.762 11.734c5.4727 1.4805 9.8867 6.1328 11.316 12.891 1.4219 6.7578-0.58984 14.34-5.2148 19.469-4.2891 4.7656-10.07 5.3359-17.738 5.3359l-47.98-0.41016c-7.2305-0.058593-7.2891 7.8125-0.085937 8.3438l67.438 5.1328c14.434 1.0977 30.695 22.289 16.504 54.113-3.2656 6.9922 5.5938 8.9414 8.3008 2.6914 6.9297-15.555 10.184-28.332 6.4219-40-1.5977-5-2.1953-7.8203 3.8867-7.707 5.8438 0.089844 11.172-0.64453 15.918-2.9258 5.625-2.7656 5.625-3.125 8.4375 1.6562 1.2461 2.1094 2.9648 3.9141 4.8359 5.5742 7.9414 7.1562 18.898 9.5352 29.473 7.9141 3.9141-0.59766 4.7656-0.22266 3.7383 5.1445l-7.5039 39.496c-2.8711 15.055-12.887 32.289-26.004 34.246l-9.2227 1.375c-7.8203 1.1758-7.3789 5.957 0.35938 7.4883l8.8633 1.7812c11.109 2.1914 9.8359 5.3516 10.633 15.258l8.2656 100.92c0.92188 11.055-2.0781 14.758-11.969 14.758h-125.5c-6.7852 0-11.082-3.7656-9.8086-13.535l14.582-111.09c1.0078-7.4961-1.0117-15.645-6.7305-23.523l-48.059-66.254c-3.9648-4.8242-4.9766-9.2656-4.1484-19.77l4.625-58.367c0.77734-9.7891 10.309-12.844 17.602-11.746z" />
            <path d="m256.64 196.08c0.027343 0.066406 0.050781 0.12109 0.078125 0.1875-0.050782-0.035156-0.14062-0.066406-0.20312-0.066406 0.035156-0.050781 0.097656-0.085938 0.125-0.12109z" />
          </g>
        </svg>
      </div>
      <div className="content-wrapper pb-14">
        <div className="max-w-xl mx-auto p-4 text-2xl">
          {/* -- */}
          <h1 className="text-8xl font-bold text-center mb-4">
            It's Time To Strike!
          </h1>
          In support of the rail workers and the millions of Americans who work
          without sufficient time off to take care of themselves and their
          families, we're calling for a general strike.
          <button
            className="bg-cyan-600 rounded mt-4 p-2 w-full font-bold hover:bg-cyan-700"
            onClick={() => {
              window.scrollTo({
                top: document.getElementById('form')!.getBoundingClientRect()
                  .top,
                behavior: 'smooth',
              });
            }}
          >
            Let's Do This!
          </button>
          {/* -- */}
          <h2 className="text-4xl font-bold text-center mb-4 mt-12">
            Our Demands
          </h2>
          <ul className="list-disc pl-8 pr-8">
            <li>10 paid sick days per year</li>
            <li>20 paid vacation days per year</li>
            <li>12 paid company holidays per year</li>
          </ul>
          {/* -- */}
          <h2 className="text-4xl font-bold text-center mb-4 mt-12">
            What about other rights?
          </h2>
          We're tackling one thing at a time to keep the movement focused and
          avoid splitting support.
          <h2 className="text-4xl font-bold text-center mb-4 mt-12">
            How do I strike?
          </h2>
          When it's time to strike, simply walk away from your job. Make sure to
          tell your co-workers and customers why and see if they'll join you.
          Then, you're free to picket and call your{' '}
          <a
            className="underline"
            target="_none"
            rel="noopener noreferrer"
            href="https://www.congress.gov/members/find-your-member"
          >
            representatives
          </a>
          .{/* -- */}
          <h2 className="text-4xl font-bold text-center mb-4 mt-12">
            When does the strike start?
          </h2>
          Once we have enough participants for critical mass, we'll send out an
          announcement. This ensures all participants share the same amount of
          risk as well as creating the largest impact.
          {/* -- */}
          <h2 className="text-4xl font-bold text-center mb-4 mt-12">
            How long will the strike last?
          </h2>
          We know that striking can be nerve-racking if you haven't done it
          before. That's why we'll be starting with a series of short burst
          strikes (1-2 hours) to raise awareness and as a warning shot to
          corporations and legislatures of what awaits them if our demands are
          not met. If a full-on strike does happen, it could last a couple of
          days to a week or more. But based on previous stikes, and the
          corporate reaction the pandemic shutdowns, we are optimistic that the
          response would be swift.
          {/* -- */}
          <h2 className="text-4xl font-bold text-center mb-4 mt-12">
            What if I can't strike?
          </h2>
          Nobody can compel you to work. Period. But if you have a lower risk
          tolerance or work in field where people depend on you for critical
          services, you can show support by encouraging others to join the
          strike, and by voicing your support during the strike.
          {/* -- */}
          <h2 className="text-4xl font-bold text-center mb-4 mt-12">
            Can a strike even work?
          </h2>
          There are multiple cases of general strikes working throughout
          history. But the most compelling evidence for their efficacy is that
          in 1947, congress made it illegal for unions to organize them. Also,
          the math is on our side. For every hour you don't work, your boss
          loses not only the value you would have created, but the value that
          would have been created by those who are dependent on your job.
          <h2 className="text-4xl font-bold text-center mb-4 mt-12" id="form">
            Count Me In!
          </h2>
          We'll notify you when it's time to strike as well as other ways you
          can help out.
          {/* Notify me when the strike starts */}
          <br />
          <br />
          {hasSubmitted ? (
            'Thanks for your support, now spread the word!'
          ) : (
            <>
              <label>
                First Name
                <input
                  type="text"
                  className="w-full rounded p-2 text-black"
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    setFirstName(val);
                  }}
                />
              </label>
              <label>
                Email
                <input
                  type="email"
                  className="w-full rounded p-2 text-black"
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    setEmail(val);
                  }}
                />
              </label>
              <label className="text-sm">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={accepted}
                  onChange={() => setAccepted(!accepted)}
                />
                I understand that striking comes with inherent risks and that my
                actions are my own.
              </label>
              <div>{error}</div>
              <button
                onClick={subscribe}
                className="bg-cyan-600 rounded mt-4 p-2 w-full hover:bg-cyan-700 disabled:bg-slate-500 font-bold"
                disabled={!firstName || !email || !accepted || isSubmitting}
              >
                I'm Ready!
              </button>
            </>
          )}
          {/* -- */}
          <h2 className="text-4xl font-bold text-center mb-4 mt-12" id="form">
            Follow us!
          </h2>
          Twitter:{' '}
          <a
            href="https://twitter.com/Join_The_Strike"
            target="_none"
            rel="noopener noreferrer"
          >
            @Join_The_Strike
          </a>
          <br />
          Discord:{' '}
          <a
            href="https://discord.gg/2bFTQHxg"
            target="_none"
            rel="noopener noreferrer"
          >
            https://discord.gg/2bFTQHxg
          </a>
          {/* -- */}
          <h2 className="text-4xl font-bold text-center mb-4 mt-12">
            Facts about our cause:
          </h2>
          <ul className="list-disc pl-8 pr-8">
            <li>28 million Americans have no paid vacation or holidays.</li>
            <li>
              Midieval peasants had more time-off than you, with 8+ weeks per
              year.
            </li>
            <li>Only 5 other countries have no guaranteed time-off.</li>
            <li>People take less PTO now than they did 30-40 years ago.</li>
            <li>
              The average person catches 2-3 colds per year, with children
              experiencing more.
            </li>
            <li>84% of workers go in sick to work.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
